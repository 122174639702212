import { CssBaseline, GlobalStyles } from "@mui/material"
import { createRoot } from "react-dom/client"
import { Router } from "app/components/Router"
import routes from "app/routes.json"
import { ConnectedUserProvider } from "app/components/ConnectedUserProvider"
import { MuiTheme } from "app/components/MuiTheme"
import { NavProvider } from "app/components/NavProvider"
import { SnackbarProvider } from "app/components/SnackbarProvider"
import { FlashMessages } from "app/components/FlashMessages"
import * as React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"
import { ClientErrorPage } from "app/components/ErrorPage"
import { ConnectedUser } from "./domains/user/model"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { setDefaultOptions } from "date-fns/setDefaultOptions"
import { fr } from "date-fns/locale/fr"
import { Router as SFRouter, RoutingData } from "symfony-ts-router"
import { AsyncClient } from "./api"
import { AsyncClientProvider } from "./components/AsyncClientProvider"

setDefaultOptions({ locale: fr })

type AppProps = {
  component: React.ElementType
  user: ConnectedUser
  props: PageProps
  nav: NavItem | null
  flashMessages: FlashMessages
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
})

const router = new SFRouter()
// Our routes object generated from Symfony's routes doesn't satisfies the
// `RoutingData` type, even if it works with the router. So we need to cast it
router.setRoutingData(routes as unknown as RoutingData)

const asyncClient = new AsyncClient(router)

const App = ({
  component: Component,
  props,
  user,
  nav,
  flashMessages,
}: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiTheme>
        <SnackbarProvider>
          <Router router={router}>
            <AsyncClientProvider client={asyncClient}>
              <NavProvider nav={nav}>
                <ConnectedUserProvider user={user}>
                  <CssBaseline />
                  <GlobalStyles
                    styles={{
                      html: { scrollBehavior: "smooth", scrollPaddingTop: 60 },
                    }}
                  />
                  <FlashMessages messages={flashMessages} />
                  <ErrorBoundary FallbackComponent={ClientErrorPage}>
                    <Component {...props} />
                  </ErrorBoundary>
                </ConnectedUserProvider>
              </NavProvider>
            </AsyncClientProvider>
          </Router>
        </SnackbarProvider>
      </MuiTheme>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

type PageParams = {
  component: React.ElementType
  props?: PageProps
  rootElementId?: string
  wrapper?: React.ElementType
  user?: ConnectedUser | null
  nav?: NavItem | null
  flashMessages?: FlashMessages | null
}

export function renderPage({
  component: Component,
  props = window.pageProps || {},
  rootElementId = "app",
  wrapper: Wrapper = App,
  user = window.user || null,
  nav = window.nav || null,
  flashMessages = window.flashMessages || null,
}: PageParams) {
  const appElement = document.getElementById(rootElementId)
  const locale = document.documentElement.lang

  if (!appElement) {
    throw new Error(`Element with id="${rootElementId}" doesn't exist`)
  }

  const root = createRoot(appElement)

  root.render(
    <Wrapper
      component={Component}
      locale={locale}
      props={props}
      user={user}
      nav={nav}
      flashMessages={flashMessages}
    />,
  )
}
