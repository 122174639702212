import { createContext, ReactNode, useContext } from "react"
import { AsyncClient } from "app/api"

type AsyncClientProviderProps = {
  client: AsyncClient
  children?: ReactNode
}

export const AsyncClientProvider = (props: AsyncClientProviderProps) => {
  return (
    <AsyncClientContext.Provider value={props.client}>
      {props.children}
    </AsyncClientContext.Provider>
  )
}

const AsyncClientContext = createContext<AsyncClient | undefined>(undefined)

export const useAsyncClient = () => {
  const ctx = useContext(AsyncClientContext)

  if (ctx === undefined) {
    throw new Error(
      "`useAsyncClient` should be used in a component wrapped in an `AsyncClientProvider`.",
    )
  }

  return ctx
}
