import { ConnectedUser } from "app/domains/user/model"
import { createContext, ReactNode, useContext } from "react"

const ConnectedUserContext = createContext<ConnectedUser | null | undefined>(
  undefined,
)

export const ConnectedUserProvider = ({
  user,
  children,
}: ConnectedUserProviderProps) => {
  return (
    <ConnectedUserContext.Provider value={user}>
      {children}
    </ConnectedUserContext.Provider>
  )
}

type ConnectedUserProviderProps = {
  user: ConnectedUser | null
  children?: ReactNode
}

export const useConnectedUser = () => {
  const context = useContext(ConnectedUserContext)

  if (context === undefined) {
    throw new Error(
      "`useConnectedUser` should be used in a `ConnectedUserProvider`",
    )
  }

  return context
}
