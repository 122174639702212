import { createContext, ReactNode, useContext } from "react"

const NavContext = createContext<NavItem | null | undefined>(undefined)

export function NavProvider({
  nav,
  children,
}: {
  nav: NavItem | null
  children: ReactNode
}) {
  return <NavContext.Provider value={nav}>{children}</NavContext.Provider>
}

export const useNav = () => {
  const context = useContext(NavContext)

  if (context === undefined) {
    throw new Error("`useNav` should be used in a `NavProvider`")
  }

  return context
}
