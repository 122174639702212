import { Box, Button, Stack, Typography } from "@mui/material"
import logo from "../../img/logo.svg"
import { useRouter } from "./Router"

type ErrorPageProps = {
  code?: number
  message?: string
}

export function ErrorPage({ code, message }: ErrorPageProps) {
  const genericErrorDescription =
    "Une erreur est survenue, nous sommes désolés pour le dérangement"

  const errorLabel = code
    ? code === 404
      ? "Page Introuvable"
      : code === 401 || code === 403
      ? "Accès refusé"
      : `Erreur ${code}`
    : null

  const errorDescription = code
    ? code === 404
      ? "La page que vous avez demandé n'existe pas."
      : code === 401 || code === 403
      ? "Vous n'êtes pas autorisé(e) à accéder à cette page"
      : message
      ? message
      : genericErrorDescription
    : genericErrorDescription

  const router = useRouter()

  return (
    <Stack
      sx={{
        alignItems: "center",
        background: "aliceblue",
        paddingTop: "200px",
        paddingBottom: "300px",
        textAlign: "center",
      }}
      direction="column"
      spacing={4}
    >
      <Box component="img" src={logo} alt="" />
      <Typography variant="h4" component="h1">
        {errorLabel}
      </Typography>
      <Typography variant="h5" component="p">
        {errorDescription}
      </Typography>
      <Button variant="outlined" href={router.generate("app_home_index")}>
        Retourner à la page d'accueil
      </Button>
    </Stack>
  )
}

export const ClientErrorPage = () => {
  return <ErrorPage />
}
