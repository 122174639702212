import { createContext, ReactNode, useContext } from "react"
import { Router as SFRouter } from "symfony-ts-router"

export const Router = (props: RouterProps) => {
  return (
    <RouterContext.Provider value={props.router}>
      {props.children}
    </RouterContext.Provider>
  )
}

type RouterProps = {
  router: SFRouter
  children?: ReactNode
}

const RouterContext = createContext<SFRouter | undefined>(undefined)

export const useRouter = () => {
  const context = useContext(RouterContext)

  if (!context) {
    throw new Error(
      "`useRouter` is meant be used in a component wrapped in a `Router`.",
    )
  }

  return context
}
