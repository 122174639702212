import type { Notification } from "app/domains/notification/model"
import { Router } from "symfony-ts-router"
import { Task } from "app/domains/task/model"
import { Product } from "app/domains/product/model"
import { FormSchema } from "@w3rone/json-schema-form"
import { Report } from "app/domains/report/model"
import { Order } from "./domains/order/model"

export async function fetch(url: string, options?: RequestInit) {
  const defaultOptions = {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
    },
  }

  const fetchOptions = {
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...(options?.headers || {}),
    },
  }

  return window.fetch(url, fetchOptions)
}

export const fetchNotifications = async (page?: number) => {
  const url = new URL("/account/notifications", window.location.origin)

  url.searchParams.set("async", "true")

  if (page) {
    url.searchParams.set("page", page.toString())
  }

  const response = await fetch(url.toString())
  const data: ServerResponse<{
    nbUnseen: number
    items: Array<Notification>
    pagination: Pagination
  }> = await response.json()

  if (!response.ok) {
    throw response
  }

  if (!data.data) {
    throw response
  }

  return data.data
}

export class AsyncClient {
  private router: Router

  constructor(router: Router) {
    this.router = router
  }

  async validateTask(taskId: Task["id"]) {
    const url = this.router.generate("app_task_validate", { taskId })

    const response = await fetch(url)

    if (!response.ok) {
      throw response
    }

    return response.json() as Promise<ServerResponse<Task>>
  }

  async fetchProductEdit(
    productId: Product["id"],
    scope: string,
    redirect?: string,
  ) {
    const url = this.router.generate("app_product_edit", {
      productId,
      scope,
      redirect,
    })

    const response = await fetch(url)

    if (!response.ok) {
      throw response
    }

    return response.json() as Promise<
      ServerResponse<{ form: FormSchema; product: Product }>
    >
  }

  async validateReport(reportId: Report["id"]) {
    const url = this.router.generate("app_report_validate", { reportId })

    const response = await fetch(url)

    if (!response.ok) {
      throw response
    }

    return response.json()
  }

  async fetchOrderFinishIntervention(orderId: Order["id"]) {
    const url = this.router.generate("app_order_finish_intervention", {
      orderId,
      referer: null,
    })

    const response = await fetch(url)

    if (!response.ok) {
      throw response
    }

    return response.json() as Promise<ServerResponse<{ form: FormSchema }>>
  }
}
