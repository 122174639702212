import { useSnackbar } from "notistack"
import * as React from "react"

export function FlashMessages({ messages }: { messages: FlashMessages }) {
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    Object.entries(messages).forEach(([type, messages]) => {
      // We need to cast `type` because `Object.entires` doesn't keep its original type...
      // See https://fettblog.eu/typescript-better-object-keys/
      const variant = type as FlashMessageType

      messages.forEach((message) =>
        enqueueSnackbar(message, {
          variant: variant === "notice" ? "info" : variant,
          persist: true,
        }),
      )
    })
  }, [])

  return null
}
