import { CalendarTodayOutlined, KeyboardArrowDown } from "@mui/icons-material"
import {
  createTheme,
  Theme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles"
import { frFR } from "@mui/material/locale"
import { ReactNode } from "react"

let theme = createTheme(
  {
    spacing: 6,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1440,
        xxl: 2500,
      },
    },
    typography: {
      fontFamily: "'Outfit', sans-serif",
      fontSize: 13,
      fontWeightRegular: 300,
      h1: {
        fontWeight: "bold",
      },
      h2: {
        fontWeight: "bold",
      },
      h3: {
        fontWeight: "bold",
      },
      h4: {
        fontWeight: "bold",
        fontSize: "1.5rem",
        lineHeight: 1.666667,
      },
      h5: {
        fontWeight: "bold",
      },
      h6: {
        fontWeight: "bold",
      },
      body1: {
        fontSize: 14,
      },
      button: {
        textTransform: "none",
      },
    },
    palette: {
      primary: {
        main: "#232F3E",
        light: "#4f5965",
      },
      secondary: {
        main: "#FF9800",
        contrastText: "#FFFFFF",
      },
      white: {
        main: "#FFFFFF",
        contrastText: "#232F3E",
      },
      text: {
        primary: "#232F3E",
        disabled: "#9e9e9e",
      },
      success: {
        main: "#4CAF50",
      },
      error: {
        main: "#F44336",
      },
      corporate: {
        main: "#0098D8",
        finished: "#C4C4C4",
      },
      holidays: {
        areaA: "#1595D3",
        areaB: "#FDD007",
        areaC: "#4CAF50",
      },
      constructionPlannings: {
        final: "#4CAF50",
        outsourced: "#232F3E",
        preparatory: "#F39208",
        waiting: "#232F3E",
        late: "#F44336",
        default: "#232F3E",
      },
      defaultTaskBackground: {
        final: "#D9EAD3",
        outsourced: "#9f7129",
        preparatory: "#F39208",
        waiting: "#bcbcbc",
        late: "#e43232",
        internal: "#A4C2F4",
        default: "#5e5c5c",
      },
      task: {
        billing: {
          order: "#8e44ad",
        },
      },
    },
  },
  frFR,
)

theme = responsiveFontSizes(theme)

const largeBorderRadius = 17

theme = createTheme(theme, {
  components: {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        rounded: {
          "&:first-of-type": {
            borderTopLeftRadius: largeBorderRadius,
            borderTopRightRadius: largeBorderRadius,
          },
          "&:last-of-type": {
            borderBottomLeftRadius: largeBorderRadius,
            borderBottomRightRadius: largeBorderRadius,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <KeyboardArrowDown fontSize="large" />,
      },
      styleOverrides: {
        inputRoot: {
          padding: "1px 9px",
        },
        endAdornment: {
          top: "50%",
          transform: "translateY(-50%)",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          fontWeight: "bold",
          borderRadius: largeBorderRadius,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: largeBorderRadius,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,

          "&:last-child": {
            paddingBottom: 8,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
        },
        action: {
          marginTop: 0,
          marginBottom: 0,
          marginRight: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorWhite: {
          color: "white",
          opacity: 0.8,

          "&.Mui-disabled": {
            color: "white",
            opacity: 0.3,
          },

          "&.Mui-checked": {
            opacity: 1,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: "0.875rem",
          height: "25px",
        },
        label: {
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
        },
      },
      variants: [
        {
          props: { variant: "filled-invert" },
        },
        {
          props: { variant: "outlined-invert" },
        },
      ],
    },
    MuiDatePicker: {
      defaultProps: {
        components: {
          OpenPickerIcon: CalendarTodayOutlined,
        },
      },
    },
    MuiDateTimePicker: {
      defaultProps: {
        components: {
          OpenPickerIcon: CalendarTodayOutlined,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          fontWeight: "bold",
          transform: "translate(18px, 8px) scale(1)",
        },
        shrink: {
          transform: "translate(13px, -8px) scale(0.75) !important",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: largeBorderRadius,
          backgroundColor: theme.palette.common.white,
        },
        input: {
          padding: "8px 18px",
          "&:disabled": {
            cursor: "not-allowed",
          },
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: largeBorderRadius,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        iconPosition: "start",
      },
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: "secondary",
      },
      styleOverrides: {
        indicator: {
          display: "none",
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          borderBottom: "none",
        },
        body: {
          "tbody:nth-of-type(odd) > tr:nth-of-type(odd) > &, tbody:nth-of-type(even) > tr:nth-of-type(even) > &":
            {
              backgroundColor: theme.palette.grey["100"],
            },
          "tbody:nth-of-type(odd) > tr:nth-of-type(even) > &, tbody:nth-of-type(even) > tr:nth-of-type(odd) > &":
            {
              backgroundColor: theme.palette.common.white,
            },
        },
        head: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        footer: {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 16,
          paddingBottom: 16,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: theme.palette.common.white,
          },
          "&.Mui-active": {
            color: theme.palette.common.white,
          },
        },
        icon: {
          color: "inherit !important",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: largeBorderRadius,
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
          padding: theme.spacing(2),
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
})

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxl: true
  }

  interface Palette {
    corporate: {
      main: string
      finished: string
    }

    white: Palette["primary"]

    defaultTaskBackground: {
      final: string
      outsourced: string
      preparatory: string
      waiting: string
      late: string
      default: string
      internal: string
    }

    task: {
      billing: {
        order: string
      }
    }
  }

  interface PaletteOptions {
    corporate: {
      main: string
      finished: string
    }

    white: PaletteOptions["primary"]

    holidays?: {
      areaA?: string
      areaB?: string
      areaC?: string
    }

    constructionPlannings?: {
      final: string
      outsourced: string
      preparatory: string
      waiting: string
      late: string
      default: string
    }

    defaultTaskBackground: {
      final: string
      outsourced: string
      preparatory: string
      waiting: string
      late: string
      default: string
      internal: string
    }

    task?: {
      billing: {
        order: string
      }
    }
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    white: true
  }
}

export const MuiTheme = ({ children }: MuiThemeProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

type MuiThemeProps = {
  children: ReactNode
}

declare global {
  interface Window {
    __MUITHEME__: Theme
  }
}

window.__MUITHEME__ = theme
