import * as React from "react"
import { Button } from "@mui/material"
import { SnackbarProvider as SnackbarProviderNotistack } from "notistack"

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const notistackRef = React.useRef<SnackbarProviderNotistack | null>(null)

  const onClickDismiss = (key: string | number) => {
    notistackRef.current?.closeSnackbar(key)
  }

  return (
    <SnackbarProviderNotistack
      ref={notistackRef}
      action={(key) => (
        <Button
          color={"white"}
          type="button"
          onClick={() => onClickDismiss(key)}
        >
          Fermer
        </Button>
      )}
      maxSnack={99}
    >
      {children}
    </SnackbarProviderNotistack>
  )
}
